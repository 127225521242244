/* eslint-disable */
import React, { useEffect } from 'react';
import { Disconnect } from './components/Disconnect';
import Cookies from 'js-cookie';

export const DisconnectHandler = ({
  logoutHandler,
  logoutDelay,
  accessToken,
  location,
  userId,
  getUser,
  domainValue,
}) => {
  const disconnect = () => {
    Cookies.remove('last_active', {
      domain: domainValue,
    });
    logoutHandler();
  };

  const checkAuthCookiesChange = (cookies, prevCookies) => {
    const oldAccessToken = prevCookies.includes('access_token');
    const newAccessToken = cookies.includes('access_token');
    const oldUserId = prevCookies.includes('userId');
    const newUserId = cookies.includes('userId');

    if ((oldUserId && !newUserId) || (oldAccessToken && !newAccessToken)) {
      disconnect();
    }
  };

  const checkCookies = (() => {
    let lastCookie = document.cookie;

    return () => {
      const currentCookie = document.cookie;

      if (currentCookie !== lastCookie) {
        checkAuthCookiesChange(currentCookie, lastCookie);

        lastCookie = currentCookie;
      }
    };
  })();

  useEffect(() => {
    const interval = setInterval(() => checkCookies(), 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Disconnect
      location={location}
      userId={userId}
      logoutHandler={disconnect}
      accessToken={accessToken}
      logoutDelay={logoutDelay}
      getUser={getUser}
      domainValue={domainValue}
    />
  );
};
