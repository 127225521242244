/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

export const Disconnect = ({
  logoutHandler,
  accessToken,
  logoutDelay,
  location,
  userId,
  getUser,
  domainValue,
}) => {
  const [isFirstMount, setIsFirstMount] = useState(true);

  useEffect(() => {
    let timeoutId;

    const resetTimer = (delay = logoutDelay) => {
      if (isFirstMount) {
        const dateTimeTmp = new Date().getTime();
        const lastActiveTimeTmp = Cookies.get('last_active', {
          domain: domainValue,
        });

        if (lastActiveTimeTmp && dateTimeTmp - +lastActiveTimeTmp >= logoutDelay) {
          setIsFirstMount(false);
          logoutHandler();
          return;
        }

        setIsFirstMount(false);
      }

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      if (delay === logoutDelay) {
        const lastActive = new Date().getTime();
        Cookies.set('last_active', lastActive, {
          domain: domainValue,
        });
      }

      timeoutId = setTimeout(() => {
        const dateTime = new Date().getTime();
        const lastActiveTime = Cookies.get('last_active', {
          domain: domainValue,
        });
        if (lastActiveTime && dateTime - +lastActiveTime >= logoutDelay) {
          logoutHandler();
        } else if (lastActiveTime) {
          const newLogoutDelay = logoutDelay - (dateTime - +lastActiveTime);
          resetTimer(newLogoutDelay);
        }
      }, delay);
    };

    const handleUserActivity = () => {
      resetTimer();
    };

    resetTimer();

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (userId) {
          const user = await getUser();
          if (!user.data.currentUser) {
            logoutHandler();
          }
        } else if (accessToken) {
          logoutHandler();
        }
      } catch {
        logoutHandler();
      }
    })();
  }, [location]);

  return null;
};
