import Cookies from 'js-cookie';
import { CalculatorsListData } from './constants';

export const setItemInStorage = (name, object) => {
  window.sessionStorage.setItem(name, JSON.stringify(object));
};

export const getItemFromStorage = (name) => window.sessionStorage.getItem(name);

export const removeItemFromStorage = (name) => {
  window.sessionStorage.removeItem(name);
};

export const clearStorage = () => {
  window.sessionStorage.clear();
};

export const isDraftFilled = (draft) =>
  !!draft.address1 &&
  !!draft.companyId &&
  !!draft.companyName &&
  !!draft.countryId &&
  !!draft.zipCode &&
  !!draft.establishedDate &&
  !!draft.id &&
  ((draft.type !== 'global' && !!draft.industrialGroupId) ||
    (draft.type === 'global' && !!draft.subIndustryId)) &&
  !!draft.legalTypeId &&
  !!draft.name &&
  !!draft.reportDate &&
  (!CalculatorsListData[draft.type].requiredFields.numberFields ||
    CalculatorsListData[draft.type].requiredFields.numberFields.every((f) => !!draft[f])) &&
  (!CalculatorsListData[draft.type].requiredFields.stringFields ||
    CalculatorsListData[draft.type].requiredFields.stringFields.every((f) => !!draft[f]));

/* eslint-disable import/prefer-default-export */
export const formatDate = (date) => {
  if (typeof date === 'number' && date === parseInt(date, 10)) {
    return `${date}-01-01 00:00:00`;
  }
  const newDate = new Date(date);
  if (!Number.isNaN(newDate.getTime())) {
    const isoDate = newDate.toISOString().split('T')[0];
    return `${isoDate} 00:00:00`;
  }
  return '';
};

/* eslint-disable import/prefer-default-export */
export const formatDateTime = (date) => {
  if (!(date instanceof Date)) return '';

  const isoDate = date.toISOString().split('T')[0];
  const isoTime = date.toISOString().split('T')[1].split('.')[0];
  return `${isoDate} ${isoTime}`;
};

export const firstLetterToUppercase = (s) => s[0].toUpperCase() + s.slice(1);

export const mergeUserWithDraft = (user, draft) => ({
  ...user,
  countryId: draft.countryId ? draft.countryId : '',
  companyId: draft.companyId ? draft.companyId : '',
  establishedDate: new Date(draft.establishedDate),
  legalTypeId: draft.legalTypeId ? draft.legalTypeId : '',
  address1: draft.address1 ? draft.address1 : '',
  address2: draft.address2 ? draft.address2 : '',
  zipCode: draft.zipCode ? draft.zipCode : '',
  industrialGroupId: draft.industrialGroupId ? draft.industrialGroupId : '',
  turnover: draft.turnover ? draft.turnover : '',
  profit: draft.profit ? draft.profit : '',
  taxes: draft.taxes ? draft.taxes : '',
  uniqProduct: draft.uniqProduct ? draft.uniqProduct : '',
  productivity: draft.productivity ? draft.productivity : '',
  employees: draft.employees ? draft.employees : '',
  reportDate: new Date(draft.reportDate),
  type: draft.type,
});

export const mergeDraftWithUser = (user, draft) => ({
  ...draft,
  userId: user.id,
  email: user.email,
  countryId: user.countryId,
  companyId: user.companyId,
  establishedDate: new Date(user.establishedDate),
  legalTypeId: user.legalTypeId,
  address1: user.address1,
  address2: user.address2,
  zipCode: user.zipCode,
  industrialGroupId: user.industrialGroupId,
  turnover: user.turnover,
  profit: user.profit,
  taxes: user.taxes,
  uniqProduct: user.uniqProduct,
  productivity: user.productivity,
  employees: user.employees,
  reportDate: new Date(user.reportDate),
});

/**
 * рекурсивная функция для изменения свойств обьектов в camelCase
 * @param {*} obj - обект со свойствами в виде snake_case
 * @returns - обект со свойствами в виде camelCase
 */
export const snakeToCamel = (obj) => {
  const newObj = Object.keys(obj).reduce(
    (res, prop) => {
      const newPropName = prop.replaceAll(
        /._./g,
        (match) => `${match.split('_')[0]}${match.split('_')[1].toUpperCase()}`
      );
      const newPropValue =
        obj[prop] && typeof obj[prop] === 'object' ? snakeToCamel(obj[prop]) : obj[prop];
      return Array.isArray(obj) ? [...res, newPropValue] : { ...res, [newPropName]: newPropValue };
    },
    Array.isArray(obj) ? [] : {}
  );
  return newObj;
};

export const openAppLoader = () => {
  const loader = document.getElementById('loadingsScreen');
  if (loader) {
    loader.style.opacity = 1;
    loader.style.visibility = 'visible';
  }
};

export const closeAppLoader = () => {
  const loader = document.getElementById('loadingsScreen');
  if (loader) {
    loader.style.opacity = 0;
    loader.style.visibility = 'hidden';
  }
};

export const logoutUserHandler = (
  logoutApiMethod,
  disconnectSocketMethod = () => {},
  withRedirect = true
) => {
  openAppLoader();

  logoutApiMethod()
    .then((response) => {
      if (response.data.logout) {
        const domainValue = process.env.REACT_APP_DOMAIN;
        Cookies.remove('access_token', { domain: domainValue });
        Cookies.remove('userId', { domain: domainValue });
        Cookies.remove('companyId', { domain: domainValue });
        Cookies.remove('calculatorId', { domain: domainValue });
        Cookies.remove('last_active', {
          domain: domainValue,
        });
        localStorage.removeItem('currentUserData');
        localStorage.removeItem('currentCompanyData');
        disconnectSocketMethod();

        if (withRedirect) {
          window.location.href = '/logout';
        } else {
          window.location.reload();
        }
      } else {
        closeAppLoader();
      }
    })
    .catch(() => {
      closeAppLoader();
      const domainValue = process.env.REACT_APP_DOMAIN;
      Cookies.remove('access_token', { domain: domainValue });
      Cookies.remove('userId', { domain: domainValue });
      Cookies.remove('calculatorId', { domain: domainValue });
      Cookies.remove('last_active', {
        domain: domainValue,
      });
      disconnectSocketMethod();

      if (withRedirect) {
        window.location.href = '/logout';
      } else {
        window.location.reload();
      }
    });
};

export const downloadFileFromURL = (url, name) => {
  const link = document.createElement('a');
  link.download = name;
  link.href = url;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
