import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

import Header from './components/header/Header';
import Loading from './components/loading/Loading';
import Start from './views/start/Start';
import NotFound from './views/notFound/NotFound';
// import Finish from "./views/finish/Finish";
import NewAssessment from './views/ttAssessment/NewAssessment';
import EditAssessment from './views/ttAssessment/EditAssessment';
import Registration from './views/profile/Registration';
import CalculatorsRouter from './views/calculators/CalculatorsRouter/CalculatorsRouter';
import ProtectedRoute from './views/ttAssessment/ProtectedRoute';
import LoadedDraftRoute from './views/ttAssessment/LoadedDraftRoute';
import SupportChat from './modules/tt-concierge';

import { AppContext, CalculatorsContext, ErrorContext, UserContext } from './context';
import { getDraft } from './api/rest/list';

import {
  GET_COMPANIES,
  GET_COUNTRIES,
  GET_INDUSTIAL_GROUPS,
  GET_SUBINDUSTRIES,
  GET_LEGAL_TYPES,
} from './api/apollo/api';

import { GetTypesAsArray } from './constants';
import { clearStorage, getItemFromStorage } from './utils';

const initialLoaderFlags = {
  companiesData: false,
  countriesData: false,
  indGroupsData: false,
  subIndData: false,
  legalTypesData: false,
};

const { REACT_APP_CONCIERGE_URL, REACT_APP_CONCIERGE_SECRETS } = process.env;

const AppRouter = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    changeUserCompanies,
    changeCountriesList,
    changeIndustrialGroups,
    changeSubIndustries,
    changeLegalTypes,
    setDraft,
    draftId,
    user,
  } = useContext(UserContext);

  const { setErrorAlert } = useContext(ErrorContext);
  const { createCalculatorList } = useContext(CalculatorsContext);
  const { loaded, changeAppLoading, changeAppLoaded } = useContext(AppContext);

  const laSalleGreenTheme = {
    headerGradient: 'linear-gradient(99.65deg, #143471 100%, #1550BD 100%)',
    sendButtonColor: '#143471',
    messageBackgroundColor: '#E3EDFF',
    supportButton: '#143471',
  };

  const [loadingNumber, setLoadingNumber] = useState(initialLoaderFlags);
  const [startLoading, setStartLoading] = useState(true);
  const [showSupportChat, setShowSupportChat] = useState(false);

  useEffect(() => {
    if (location.pathname.includes('/startReport') || location.pathname.includes('/editReport')) {
      clearStorage();
    } else if (!draftId) {
      const savedId = getItemFromStorage('SELECTED_DRAFT_ID');
      const loadDraft = (id) => {
        getDraft({ id })
          .then((res) => {
            const { data } = res.data;
            const [draft] = data ?? [];
            const name = GetTypesAsArray().find((n) => draft.type === n);
            if (name && draft) {
              setDraft(draft);
              createCalculatorList(name);
            }
          })
          .catch((err) => {
            setErrorAlert('Cannot load the draft, please thy again later', err);
          });
      };
      if (savedId) {
        loadDraft(savedId);
      } else {
        navigate('/drafts');
      }
    }
  }, [location]);

  useEffect(() => {
    changeAppLoading(0);
    changeAppLoaded(false);
    setLoadingNumber(initialLoaderFlags);
    setStartLoading(true);
  }, []);

  const increaseLoading = (respName) => {
    setLoadingNumber((obj) => ({
      ...obj,
      [respName]: true,
    }));
  };

  // users data
  const [getCompaniesData, { data: companiesData }] = useLazyQuery(GET_COMPANIES);

  const [getCountriesData, { data: countriesData }] = useLazyQuery(GET_COUNTRIES);
  const [getIndGroupsData, { data: indGroupsData }] = useLazyQuery(GET_INDUSTIAL_GROUPS);
  const [setSubIndData, { data: subIndData }] = useLazyQuery(GET_SUBINDUSTRIES);
  const [getLegalTypesData, { data: legalTypesData }] = useLazyQuery(GET_LEGAL_TYPES);

  useEffect(() => {
    if (!startLoading || !user) return;
    getCountriesData();
    getIndGroupsData();
    setSubIndData();
    getLegalTypesData();
    setStartLoading(false);
  }, [startLoading, user]);

  useEffect(() => {
    if (!user?.id) return;
    getCompaniesData({ variables: { id: user.id } });
  }, [user]);

  useEffect(() => {
    const keys = Object.keys(loadingNumber);
    const count = keys.reduce((res, k) => res + (loadingNumber[k] ? 1 : 0), 0);
    changeAppLoading(count / keys.length);
  }, [loadingNumber]);

  useEffect(() => {
    if (companiesData) {
      increaseLoading('companiesData');
      changeUserCompanies(companiesData.showAllCompaniesByUserId);
    }
  }, [companiesData]);

  useEffect(() => {
    if (countriesData) {
      increaseLoading('countriesData');
      changeCountriesList(countriesData.showCountries);
    }
  }, [countriesData]);

  useEffect(() => {
    if (indGroupsData) {
      increaseLoading('indGroupsData');
      changeIndustrialGroups(indGroupsData.showIndustryGroups);
    }
  }, [indGroupsData]);

  useEffect(() => {
    if (subIndData) {
      increaseLoading('subIndData');
      changeSubIndustries(subIndData.showSubIndustries);
    }
  }, [subIndData]);

  useEffect(() => {
    if (legalTypesData) {
      increaseLoading('legalTypesData');
      changeLegalTypes(legalTypesData.showCompanyLegalTypes);
    }
  }, [legalTypesData]);

  return (
    <>
      {loaded && (
        <>
          <Header />
          <main>
            <SupportChat
              them={laSalleGreenTheme}
              showSupportChat={showSupportChat}
              setShowSupportChat={setShowSupportChat}
              companyName="TT Concierge"
              apiUrl={REACT_APP_CONCIERGE_URL} // API url
              apiSecret={REACT_APP_CONCIERGE_SECRETS} // API Authorization token
              supportMail="support@transparenterra.com"
              user=""
            />
            <Routes>
              <Route element={<ProtectedRoute />}>
                <Route exact path="/editReport/:draftId" element={<EditAssessment />} />
                <Route path="/startReport" element={<NewAssessment />} />
                <Route element={<LoadedDraftRoute />}>
                  <Route exact path="/start" element={<Start />} />
                  <Route exact path="/registration" element={<Registration />} />
                  <Route path="/report/*" element={<CalculatorsRouter />} />
                  {/* <Route exact path="/finish/*" element={<Finish />} /> */}
                </Route>
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
        </>
      )}
      <Loading />
    </>
  );
};

export default AppRouter;
