/* eslint-disable */

import clsx from 'clsx';
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'tt-ui-kit';
import CalcModal from '../../components/CalcModal/CalcModal';

import { getDraft, replaceDraft, updateDraft } from '../../api/rest/list';
import { UserContext, CalculatorsContext, ErrorContext } from '../../context';
import styles from './Registration.module.scss';
/* eslint-disable-next-line import/no-named-default */
import Form from '../../components/form/Form';
import RegistrationCustom from './RegistrationCustom.jsx';
import { CalculatorsListData } from '../../constants';
import { formatDate } from '../../utils';

const Registration = () => {
  const [validated, setValidated] = useState(false);
  const [progress, setProgress] = useState(0);
  const [initialDraft, setInitialDraft] = useState(null);
  const [selectedDraft, setSelectedDraft] = useState(null);
  const [isDisclaimerOpened, setIsDisclaimerOpened] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [isNavigate, setIsNavigate] = useState(false);
  const [company, setCompany] = useState(null);
  const [requiredFields, setRequiredFields] = useState([]);
  const [validity, setValidity] = useState({});

  const [draftIdForRemove, setDraftIdForRemove] = useState(null);
  const [choosedAction, setChoosedAction] = useState(null);
  const [years, setYears] = useState([]);
  const [reportYear, setReportYear] = useState(null);

  const { setErrorAlert } = useContext(ErrorContext);
  const { selectedCalculators, calculatorName } = useContext(CalculatorsContext);
  const {
    setDraftsList,
    setDraft,
    draftId,
    draftsList,
    changeDraftsList,
    companies,
    legalTypes,
    industrialGroups,
    prepareDraft,
    user,
  } = useContext(UserContext);
  // const { getQuestionsFile } = useContext(ApiMethodContext);

  const navigate = useNavigate();

  const getDraftData = () => {
    getDraft({ id: draftId })
      .then((res) => {
        if (res.data.success) {
          const { data }  = res.data;
          const [newDraft] = data ?? [];
          setInitialDraft({ ...newDraft });
          setSelectedDraft({ ...newDraft });
          if (CalculatorsListData[calculatorName].isSingleInYear && newDraft.periodReportDate)
            setReportYear(newDraft.periodReportDate.getFullYear());
        }
      })
      .catch((err) => {
        setErrorAlert('Draft loading error', err);
      });
  };

  const getCompanyDrafts = (params) => {
    getDraft({...params})
      .then((res) => {
        const { data, success } = res.data;
        if (success) {
          setDraftsList(data);
        }
      })
      .catch((err) => {
        setErrorAlert('Drafts list loading error', err);
      });
  };

  useEffect(() => {
    if (!draftId) return;
    getDraftData();
  }, [draftId]);

  useEffect(() => {
    if (!initialDraft || !initialDraft.companyId || !initialDraft.type) return;
    getCompanyDrafts({ company_id: initialDraft.companyId, type: initialDraft.type });
  }, [initialDraft, user]);

  useEffect(() => {    
    if (!companies || !initialDraft || !legalTypes || !industrialGroups) return;
    setCompany(companies[initialDraft.companyId]);
  }, [initialDraft, companies, legalTypes, industrialGroups]);

  useEffect(() => {
    if (!draftsList || !draftsList.length) return;
    const newYears = draftsList.reduce(
      (res, d) => (d.periodReportDate ? res.add(d.periodReportDate.getFullYear()) : res),
      new Set()
    );
    setYears([...newYears]);
  }, [draftsList]);

  useEffect(() => {
    setIsNavigate(false);
  }, []);

  const numberFilter = /^(\d+\.)?\d+$/;

  useEffect(() => {
    if (!calculatorName) return;
    setRequiredFields({
      ...CalculatorsListData[calculatorName].requiredFields,
    });
  }, [calculatorName]);

  useEffect(() => {
    if (!requiredFields) return;
    setValidity({
      value: 0,
      total: Object.keys(requiredFields).reduce((sum, key) => {
        const fieldsCount =
          key === 'reportYear' && requiredFields.reportYear ? 1 : requiredFields[key].length;
        return sum + fieldsCount;
      }, 0),
    });
  }, [requiredFields]);

  const validatedFields = () => {
    if (!selectedDraft) return 0;

    let totalValidated = 0;
    totalValidated += requiredFields.numberFields
      ? requiredFields.numberFields.filter((n) => numberFilter.test(selectedDraft[n])).length
      : 0;
    totalValidated += requiredFields.stringFields
      ? requiredFields.stringFields.filter((n) => !!selectedDraft[n]).length
      : 0;
    totalValidated += requiredFields.reportYear && reportYear ? 1 : 0;
    totalValidated += requiredFields.dateFields
      ? requiredFields.dateFields.filter((n) => !Number.isNaN(Date.parse(selectedDraft[n]))).length
      : 0;
    return totalValidated;
  };

  useEffect(() => {
    setValidity((value) => ({
      value: validatedFields(),
      total: value.total,
    }));
  }, [selectedDraft, reportYear]);

  useEffect(() => {
    const newCheck = validity.value === validity.total;
    setValidated(newCheck);
    const progressValue = validity.value / validity.total;
    setProgress(
      Number.isNaN(progressValue) || progressValue === 0 ? '1px' : `${progressValue * 100}%`
    );
  }, [validity]);

  const onChange = (e) => {
    const { name, value } = e.target;
    setSelectedDraft((newDraft) => ({
      ...newDraft,
      [name]: value,
    }));
  };

  const onChangeDate = (e) => {
    const newValue = e?.target?.value;
    setDraftIdForRemove(null);
    setReportYear(newValue ? newValue.getFullYear() : null);
  };

  const warningModalOpen = () => {
    setIsWarningModalOpen(true);
  };

  useEffect(() => {
    if (!reportYear) return;
    const exist = draftsList.find((d) => d.periodReportDate && d.periodReportDate.getFullYear() === reportYear);
    if (exist && initialDraft.id === exist.id) return;
    const newDraft = {
      ...initialDraft,
      turnover: selectedDraft.turnover,
      profit: selectedDraft.profit,
      taxes: selectedDraft.taxes,
      uniqProduct: selectedDraft.uniqProduct,
      productivity: selectedDraft.productivity,
      employees: selectedDraft.employees,
    };
    setSelectedDraft({
      ...(exist ?? newDraft),
      periodReportDate: new Date(reportYear, 0, 1),
    });
    if (exist) {
      warningModalOpen();
    }
  }, [reportYear]);

  const goToNextPage = () => {
    const newDraftList = draftsList.map((d) =>
      d.id === selectedDraft.id ? { ...selectedDraft } : { ...d }
    );
    changeDraftsList(newDraftList);
    setDraft(selectedDraft);
    setIsNavigate(true);
  };

  useEffect(() => {
    if (!choosedAction) return;
    if (choosedAction === 'load') {
      goToNextPage();
    }
    if (choosedAction === 'new') {
      setDraftIdForRemove(selectedDraft.id);
      setSelectedDraft({
        ...initialDraft,
        periodReportDate: new Date(reportYear, 0, 1),
      });
    }
    setIsWarningModalOpen(false);
    setChoosedAction(null);
  }, [choosedAction]);

  const resetChoosing = () => {
    setChoosedAction(null);
    setReportYear(null);
    setSelectedDraft({
      ...initialDraft,
      turnover: selectedDraft.turnover,
      profit: selectedDraft.profit,
      taxes: selectedDraft.taxes,
      uniqProduct: selectedDraft.uniqProduct,
      productivity: selectedDraft.productivity,
      employees: selectedDraft.employees,
      name: selectedDraft.name,
    });
    setIsWarningModalOpen(false);
  };

  const saveDraft = async () => {
    const input = {
      id: selectedDraft.id,
      user_id: selectedDraft.userId,
      annual_turnover: `${selectedDraft.turnover ?? 0}`,
      net_profit: `${selectedDraft.profit ?? 0}`,
      year_taxes: `${selectedDraft.taxes ?? 0}`,
      number_unique_products: `${selectedDraft.uniqProduct ?? 0}`,
      annual_productivity_each_position: `${selectedDraft.productivity ?? 0}`,
      employees_number: `${selectedDraft.employees ?? 0}`,
      period_report_date: formatDate(selectedDraft.periodReportDate),
      type: selectedDraft.type,
      name: selectedDraft.name ?? '',
    };

    if (draftIdForRemove) {
      replaceDraft({
        old_id: draftIdForRemove,
        input: { ...input },
      }).then((resp) => {
        const newDraft = prepareDraft(resp.data);
        setSelectedDraft({ ...newDraft });
      });
    } else {
      updateDraft({ ...input }).then((resp) => {
        const newDraft = prepareDraft(resp.data);
        setSelectedDraft({ ...newDraft });
      });
    }
  };

  const startNavigation = async (url) => {
    await saveDraft();
    navigate(url);
  };

  useEffect(() => {
    if (!isNavigate) return;
    const url = selectedDraft.lastPosition
      ? selectedDraft.lastPosition
      : selectedCalculators[0].url;
    startNavigation(url);
  }, [isNavigate]);

  return (
    <>
      {initialDraft && selectedDraft && (
        <Modal
          open={isWarningModalOpen}
          onClose={() => setIsWarningModalOpen(false)}
          title="Start new assessment"
          closeOnlyByControls
        >
          <div className={styles.text}>
            {`You are about to start a new "${initialDraft.name}" assessment.
            Please note that all data from the last saved draft will be lost,
            and the "${selectedDraft.name}" will disappear from the list of all drafts.`}
          </div>
          <div className={clsx(styles.buttonBlock, styles.alertBB)}>
            <Button type="default" onClick={() => resetChoosing()}>
              Cancel
            </Button>
            <Button type="primary" onClick={() => setChoosedAction('load')}>
              Load previous
            </Button>
            <Button type="primary" onClick={() => setChoosedAction('new')}>
              Replace with new
            </Button>
          </div>
        </Modal>
      )}
      <CalcModal
        opened={isDisclaimerOpened}
        closeModalFunc={() => setIsDisclaimerOpened(false)}
        headerContent="Financial data"
        okBtnText="OK"
      >
        All this data is mandatory. Please fill out required fields.
      </CalcModal>
      <div className={styles.profileContainer}>
        <div className={styles.profile}>
          <div
            className={styles.progressBar}
            style={{
              background: `linear-gradient(to right, #01A0C6 ${progress}, transparent ${progress}, transparent 100%)`,
            }}
          />
          {selectedDraft && (
            <Form headerContent="General Information">
              {company && (
                <div className={styles.infoBlock}>
                  <div>
                    <div className={styles.info}>Company name</div>
                    <div className={styles.text}>{company.companyName}</div>
                  </div>
                  <div>
                    <div className={styles.info}>Address</div>
                    <div className={styles.text}>{company.address1}</div>
                  </div>
                  <div>
                    <div className={styles.info}>Company legal type</div>
                    <div className={styles.text}>{legalTypes[company.legalTypeId].name}</div>
                  </div>
                  <div>
                    <div className={styles.info}>Industrial sector</div>
                    <div className={styles.text}>
                      {industrialGroups[company.industrialGroupId].name}
                    </div>
                  </div>
                  <div>
                    <div className={styles.info}>Established date</div>
                    <div className={styles.text}>{company.establishedDate ?? 'no date'}</div>
                  </div>
                </div>
              )}
              <RegistrationCustom
                onChange={onChange}
                onChangeDate={onChangeDate}
                openDisclaimer={() => setIsDisclaimerOpened(true)}
                isDisabled={!draftId}
                numberFilter={numberFilter}
                data={selectedDraft}
                years={years}
                reportYear={reportYear}
                dateDisable={!!initialDraft.periodReportDate}
              />
            </Form>
          )}
          {/* <PartialLoader loading={!draft} isLightContainer /> */}
          <div className={clsx(styles.navigation, styles.single)}>
            <Button variant="contained" onClick={goToNextPage} disabled={!validated}>
              NEXT
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Registration;
